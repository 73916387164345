import React from 'react';
import {
  TextField,
  Datagrid,
  List,
  Edit,
  FormTab,
  TextInput,
  TabbedForm,
  NumberInput,
} from 'react-admin';

export const BoxingComboConfig = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="defaults.rest_duration" />
    </Datagrid>
  </List>
);
/*
const ratios = {
  Elbows: {None: 0, Some: 15, More: 15},
  Moves: {None: 0, Some: 15, More: 15},
  Legwork: {None: 0, Some: 15, More: 15},
  Punch: {None: 100, Some: 100, More: 100},
  Defense: {None: 0, Some: 15, More: 15},
};*/
//const FullNameField = ({ratios = {}}) => <h1>{ratios.Elbowss.None}</h1>;
/*const testing = Object.entries(ratios).map(([key, value]) => {
  <span>{key} </span>;
});
FullNameField.defaultProps = {label: 'test'};
*/

export const ConfigTest = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Defaults">
        <NumberInput source="defaults.rest_duration" />
        <NumberInput source="defaults.round_duration" />
        <NumberInput source="defaults.prepare_duration" />
        <NumberInput source="defaults.no_of_rounds" />
        <TextInput source="defaults.gen_cmb_skill" />
        <TextInput source="defaults.gen_cmb_complexity" />
        <TextInput source="defaults.gen_cmb_intensity" />
        <TextInput source="defaults.gen_cmb_stance" />
        <TextInput source="defaults.cmb_typemix" />
        <TextInput source="ratios.Elbows.Some" />
      </FormTab>

      <FormTab label="Ratios">
        <List {...props}>
          <Datagrid>
            {/* {Object.entries(ratios).map(([key, value]) => (
              <h1>
                {key}: {value['None']}
              </h1>
            ))} */}
          </Datagrid>
        </List>
      </FormTab>
      <FormTab label="Complexities"></FormTab>
      <FormTab label="Skills">
        <NumberInput source="skills.Intermediate" />
        <NumberInput source="skills.Experienced" />
        <NumberInput source="skills.Basic" />
      </FormTab>
      <FormTab label="Intensities"></FormTab>
      <FormTab label="Repeats"></FormTab>
    </TabbedForm>
  </Edit>
);
