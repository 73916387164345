import React from 'react';
import {Admin, Resource} from 'react-admin';
import './App.css';
import {FirebaseAuthProvider} from 'react-admin-firebase';

import SportsMma from '@material-ui/icons/SportsMma';

import {MovesEdit, MovesList, MovesCreate} from './components/Moves';

import {BoxingComboConfig, ConfigTest} from './components/BoxingConfig';
import {BoxingComboList, ComboEdit, ComboCreate} from './components/combos';
import {dataProvider} from './providers/dataProvider';

const config = {
  apiKey: 'AIzaSyASS6OJhBBsKk1kWe8GB__TVKMHA9lZjwM',
  authDomain: 'practice-85d29.firebaseapp.com',
  databaseURL: 'https://practice-85d29.firebaseio.com',
  projectId: 'practice-85d29',
  storageBucket: 'practice-85d29.appspot.com',
  messagingSenderId: '652985556205',
  appId: '1:652985556205:web:1024f238069eb3b2ad40e4',
};
const firebaseAuthProvider = FirebaseAuthProvider(config);

function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={firebaseAuthProvider}>
      <Resource
        title="Boxing Moves"
        name="boxing-moves"
        options={{label: 'Moves'}}
        icon={SportsMma}
        list={MovesList}
        edit={MovesEdit}
        create={MovesCreate}
      />
      <Resource
        title="Combos"
        name="bm-combo"
        options={{label: 'Combos'}}
        icon={SportsMma}
        list={BoxingComboList}
        edit={ComboEdit}
        create={ComboCreate}
      />
      <Resource
        title="Combo Config"
        name="bm-config"
        options={{label: 'Combo Config'}}
        icon={SportsMma}
        list={BoxingComboConfig}
        edit={ConfigTest}
      />
    </Admin>
  );
}

export default App;
