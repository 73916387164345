import React from 'react';
import {
  TextField,
  TextInput,
  Datagrid,
  List,
  UrlField,
  ChipField,
  Edit,
  SimpleForm,
  ArrayField,
  NumberInput,
  Create,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Filter,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {makeStyles, Chip} from '@material-ui/core';
import Trunc from './customFields';
import ReactPlayer from 'react-player';
import GifPlayer from 'react-gif-player';

const descPanel = ({id, record, resource}) => (
  <div dangerouslySetInnerHTML={{__html: record.description}} />
);

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));
const QuickFilter = ({label}) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <QuickFilter label="Defense" source="defense" defaultValue="Defense" />
    <QuickFilter
      label="Defense (kickboxing)"
      source="defenseKick"
      defaultValue="Defense (kickboxing)"
    />
    <QuickFilter label="Footwork" source="footwork" defaultValue="Footwork" />
    <QuickFilter label="Kick" source="kick" defaultValue="Kick" />
    <QuickFilter
      label="Knees & Elbows"
      source="knee"
      defaultValue="Knees & Elbows"
    />
    <QuickFilter label="Punch" source="punch" defaultValue="Punch" />
  </Filter>
);

export const MovesList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="Moves"
      filters={<PostFilter />}>
      <Datagrid rowClick="edit" expand={descPanel}>
        <TextField source="shortName" />
        <TextField source="longName" />
        <ChipField source="type" />
        <BooleanField source="stance" valueLabelFalse={false} />
        <Trunc />
        <TextField source="skillLevel" />
        <ArrayField source="medias" fullWidth>
          <Datagrid style={{width: 400}}>
            <UrlField source="url" />
          </Datagrid>
        </ArrayField>
      </Datagrid>
    </List>
  );
};

export const MovesEdit = (props) => (
  <Edit {...props} title="Move Editor">
    <SimpleForm>
      <AutocompleteInput
        source="type"
        choices={[
          {id: 'Defense', name: 'Defense'},
          {id: 'Defense (kickboxing)', name: 'Defense (kickboxing)'},
          {id: 'Footwork', name: 'Footwork'},
          {id: 'Kick', name: 'Kick'},
          {id: 'Knees & Elbows', name: 'Knees & Elbows'},
          {id: 'Punch', name: 'Punch'},
        ]}
      />
      <TextInput source="shortName" />
      <TextInput source="longName" />
      <BooleanInput label="Stance" source="stance" />
      <RichTextInput source="description" />
      <NumberInput source="skillLevel" min={1} max={3} />
      <ArrayInput source="medias" label="">
        <SimpleFormIterator>
          <AutocompleteInput
            source="type"
            choices={[
              {id: 'VIDEO', name: 'Video'},
              {id: 'IMAGE', name: 'Image'},
              {id: 'GIF', name: 'Gif'},
            ]}
          />
          <TextInput source="url" label="url" />
          <MediaTextField />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

const MediaTextField = (props) => {
  return (
    <>
      {props.record.type === 'VIDEO' && <ReactPlayer url={props.record.url} />}
      {props.record.type === 'IMAGE' && (
        <img src={props.record.url} alt={`media type ${props.record.url}`} />
      )}
      {props.record.type === 'GIF' && <GifPlayer gif={props.record.url} />}
    </>
  );
};

export const MovesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteInput
        source="type"
        choices={[
          {id: 'Defense', name: 'Defense'},
          {id: 'Defense (kickboxing)', name: 'Defense (kickboxing)'},
          {id: 'Footwork', name: 'Footwork'},
          {id: 'Kick', name: 'Kick'},
          {id: 'Knees & Elbows', name: 'Knees & Elbows'},
          {id: 'Punch', name: 'Punch'},
        ]}
      />
      <TextInput source="shortName" />
      <TextInput source="longName" />
      <BooleanInput label="Stance" source="stance" />
      <RichTextInput source="description" />
      <NumberInput source="skillLevel" min={1} max={3} />
      <ArrayInput source="medias" label="">
        <SimpleFormIterator>
          <AutocompleteInput
            label="Media Type"
            source="type"
            choices={[
              {id: 'VIDEO', name: 'Video'},
              {id: 'IMAGE', name: 'Image'},
              {id: 'GIF', name: 'Gif'},
            ]}
          />
          <TextInput source="url" label="url" />
          <MediaTextField />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
