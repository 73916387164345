import React from 'react';

const Trunc = ({source, record = {}}) => (
  <div style={{width: 400}}>
    <span>
      {JSON.stringify(record.description).length > 50
        ? JSON.stringify(record.description).slice(1, 50) + '...'
        : record.description}
    </span>
  </div>
);
Trunc.defaultProps = {label: 'Description'};

export default Trunc;
