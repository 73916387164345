import React from 'react';
import {
  TextField,
  Datagrid,
  List,
  DateField,
  ChipField,
  SimpleForm,
  Edit,
  Create,
  ArrayInput,
  SimpleFormIterator,
  useGetList,
  AutocompleteInput,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {ListItemText} from '@material-ui/core';

const Aside = ({record}) => (
  <div
    style={{
      width: '25em',
      margin: '1em',
      padding: '2.5em',
      backgroundColor: 'white',
      textAlign: 'center',
      borderRadius: 10,
    }}>
    <Typography variant="h6">Current Combo Details </Typography>
    <ListItemText primary={record.comboText} secondary="Combo" />
    <ListItemText primary={record.comboCode} secondary="Shortname Steps" />
    <ListItemText primary={record.maxSkill} secondary="Max Skill" />

    <ListItemText primary={record.modifiedOn} secondary="Last Modified" />
  </div>
);
export const BoxingComboList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <ChipField source="comboCode" />
      <TextField source="comboText" />
      <TextField source="maxSkill" />
      <TextField source="stepCnt" />
      <TextField source="mmedia" />
      <DateField source="modifiedOn" />
    </Datagrid>
  </List>
);

export const ComboEdit = (props) => {
  const {data, loading, error} = useGetList(
    'boxing-moves',
    {page: 1, perPage: 1000},
    {},
    {}
  );
  if (loading || error) return null;

  const choicess = Object.values(data).map((item) => ({
    id: item.shortName,
    name: item.longName,
  }));

  return (
    <Edit title="Combo Editor" aside={<Aside />} {...props}>
      <SimpleForm>
        <ArrayInput source="steps" label="">
          <SimpleFormIterator>
            <AutocompleteInput label="Step" choices={choicess} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const ComboCreate = (props) => {
  const {data, loading, error} = useGetList(
    'boxing-moves',
    {page: 1, perPage: 1000},
    {},
    {}
  );
  if (loading || error) return null;

  const choices = Object.values(data).map((item) => ({
    id: item.shortName,
    name: item.longName,
  }));

  return (
    <Create {...props}>
      <SimpleForm>
        <ArrayInput source="steps" label="">
          <SimpleFormIterator>
            <AutocompleteInput choices={choices} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
