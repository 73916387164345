import {fetchUtils} from 'react-admin';

const apiUrl = 'https://1-dot-ca-plat-api-dev.uc.r.appspot.com/api/v1/admin';
const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  options.headers.set('Authorization', 'Basic YWNhZGVteWdvQCZleiY2MjkoaH1wUktoKQ==');
  return fetchUtils.fetchJson(url, options);
};
const httpClient = fetchJson;

export const dataProvider = {
  getList: async (resource, params) => {
    const {json} = await httpClient(`${apiUrl}/${resource}`);
    const typeFilter = [];
    const queryFilter = params.filter['q'];
    const defenseFilter = params.filter['defense'];
    const defenseKickFilter = params.filter['defenseKick'];
    const footworkFilter = params.filter['footwork'];
    const kickFilter = params.filter['kick'];
    const kneeFilter = params.filter['knee'];
    const punchFilter = params.filter['punch'];
    if (!defenseFilter) {
      typeFilter.filter((e) => e !== defenseFilter);
    } else {
      typeFilter.push(defenseFilter);
    }
    if (!defenseKickFilter) {
      typeFilter.filter((e) => e !== defenseKickFilter);
    } else {
      typeFilter.push(defenseKickFilter);
    }
    if (!footworkFilter) {
      typeFilter.filter((e) => e !== footworkFilter);
    } else {
      typeFilter.push(footworkFilter);
    }
    if (!kickFilter) {
      typeFilter.filter((e) => e !== kickFilter);
    } else {
      typeFilter.push(kickFilter);
    }
    if (!kneeFilter) {
      typeFilter.filter((e) => e !== kneeFilter);
    } else {
      typeFilter.push(kneeFilter);
    }
    if (!punchFilter) {
      typeFilter.filter((e) => e !== punchFilter);
    } else {
      typeFilter.push(punchFilter);
    }
    const map = {
      'boxing-moves': 'moves',
      'bm-combo': 'combos',
      'bm-config': null,
    };

    const result = json[map[resource]]
      .filter((e) => {
        if (!queryFilter) {
          return true;
        }
        return e.description.toUpperCase().includes(queryFilter.toUpperCase());
      })
      .filter((e) => {
        if (typeFilter.length === 0) {
          return true;
        }
        return typeFilter.includes(e.type);
      });

    const {field, order} = params.sort;
    result.sort(dynamicSort(field, order));

    const {page, perPage} = params.pagination;
    const showedResult = result.slice((page - 1) * perPage, page * perPage);

    return {
      data: showedResult,
      total: result.length,
    };
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
      data: json,
    })),
  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({
      data: {...params.data, id: json.id},
    })),
  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: json})),
  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({json}) => ({data: json})),
};
//https://marmelab.com/react-admin/Tutorial.html#connecting-to-a-real-api ,dataProvider.getOne('posts', { id: 123 });
function dynamicSort(property, order) {
  let sortOrder = 1;
  if (order === 'DESC') {
    sortOrder = -1;
  }
  return function (a, b) {
    let aProp = a[property];
    let bProp = b[property];
    if (!a.hasOwnProperty(property)) {
      aProp = '';
    }
    if (!b.hasOwnProperty(property)) {
      bProp = '';
    }
    const result = aProp < bProp ? -1 : aProp > bProp ? 1 : 0;
    return result * sortOrder;
  };
}
